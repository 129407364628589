import PropTypes from "prop-types";
import React, { Fragment } from "react";
import ShopTopAction from "../../components/product/ShopTopAction";

const ShopTopbar = ({
  getLayout,
  setFilterSortParams,
  productCount,
  sortedProductCount,
  products,
  pagination
}) => {
  return (
    <Fragment>
      {/* shop top action */}
      <ShopTopAction
        getLayout={getLayout}
        setFilterSortParams={setFilterSortParams}
        productCount={productCount}
        sortedProductCount={sortedProductCount}
        products={products}
        pagination={pagination}
      />

    </Fragment>
  );
};

ShopTopbar.propTypes = {
  setFilterSortParams: PropTypes.func,
  getLayout: PropTypes.func,
  productCount: PropTypes.number,
  sortedProductCount: PropTypes.number
};

export default ShopTopbar;
