import { React, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import clsx from "clsx";
import MenuCart from "./sub-components/MenuCart";
import cogoToast from "cogo-toast";
import { setSelectedStore, setStoreCart } from "../../store/slices/cart-slice";

const IconGroup = ({ iconWhiteClass }) => {
  const dispath = useDispatch();
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const { address } = useSelector((state) => state.store);
  const { selectedStore } = useSelector((state) => state.cart);

  const handleClick = (e) => {
    e.currentTarget?.nextSibling?.classList.toggle("active");
  };

  const modalRef = useRef();

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.add("active");
  };

  const { wishlistItems } = useSelector((state) => state.wishlist);
  const { cartItems } = useSelector((state) => state.cart);

  const handleStoreChange = (e) => {
    localStorage.setItem("std", e.target.value);
    dispath(setSelectedStore(e.target.value));
    cogoToast.success("Store Changed Successfully", {
      position: "bottom-left",
    });
    dispath(setStoreCart(e.target.value));
    modalRef.current.click();
  }
  const getCurrentStore = () => {
    const index = address.findIndex((add) => add._id === selectedStore);
    if (index != -1) {
      return address[index].storeName;
    }
    else {
      dispath(setSelectedStore(address[0]._id));
      return address[0].storeName;

    }
  }

  return (
    <div className={clsx("header-right-wrap", iconWhiteClass)}>
      {
        user && user?.type === "Retailer" && address?.length !== 0 && (
          <>
            <div className="box">
              <a className="button" href="#store">Store - {getCurrentStore()}</a>
            </div>
            <div id="store" className="overlay">
              <div className="popup">
                <h2>Select Store</h2>
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="close" ref={modalRef} href="#"><i className="fa fa-times"></i></a>
                <div className="same-style account-setting">
                  <select onChange={handleStoreChange} defaultValue={selectedStore}>
                    {
                      address?.map((item, index) => {
                        return (
                          <option key={index} value={item?._id}>{item?.storeName}</option>
                        )
                      })
                    }
                  </select>
                </div>
              </div>
            </div>
          </>)
      }

      <div className="same-style account-setting">
        {isAuthenticated ? (
          <>
            <Link to={process.env.PUBLIC_URL + "/account"}>
              <button
                className="account-setting-active p-2"
                style={{
                  fontSize: "14px",
                  backgroundColor: "#dc3558",
                  borderRadius: "4px",
                  color: "#fff",
                  border: "none",
                }}
              >
                Account
              </button>
            </Link>
          </>
        ) : (
          <Link to={process.env.PUBLIC_URL + "/login"}>
            <button
              className="account-setting-active p-2"
              style={{
                fontSize: "14px",
                backgroundColor: "#dc3558",
                borderRadius: "4px",
                color: "#fff",
                border: "none",
              }}
            >
              Sign In
            </button>
          </Link>
        )}
      </div>
      <Link to={process.env.PUBLIC_URL + "/wishlist"} className="mx-3 same-style cart-wrap d-none d-lg-block">
        <button
          className="account-setting-active p-2 "
          style={{
            fontSize: "14px",
            backgroundColor: "#dc3558",
            borderRadius: "4px",
            color: "#fff",
            border: "none",
          }}
        >
          Wishlist
          <span className="count-style">
            {wishlistItems && wishlistItems.length ? wishlistItems.length : 0}
          </span>
        </button>
      </Link>
      {/* {
        isAuthenticated ? (
          <> */}
      <div
        className="same-style cart-wrap d-none d-lg-block"
        style={{ marginLeft: 0 }}
      >
        <button
          className="account-setting-active"
          onClick={(e) => handleClick(e)}
          style={{
            fontSize: "14px",
            backgroundColor: "#dc3558",
            borderRadius: "4px",
            color: "#fff",
            border: "none",
            padding: "0.5rem 1rem",
          }}
        >
          Cart
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </button>
        {/* menu cart */}
        <MenuCart />
      </div>
      <div className="same-style cart-wrap d-block d-lg-none mt-2">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">
            {cartItems && cartItems.length ? cartItems.length : 0}
          </span>
        </Link>
      </div>

      <div className="same-style mobile-off-canvas d-block d-lg-none mt-2">
        <button
          className="mobile-aside-button"
          onClick={() => triggerMobileMenu()}
        >
          <i className="pe-7s-menu " />
        </button>
      </div>
    </div>
  );
};

IconGroup.propTypes = {
  iconWhiteClass: PropTypes.string,
};

export default IconGroup;
