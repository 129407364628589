import axios from "axios";
import {
  createOrderSuccess,
  createOrderFail,
  orderPaymentSuccess,
  createOrderRequest,
  setRedirect,
  resetOrder,
  getMyOrdersRequest,
  getMyOrdersSuccess,
} from "../slices/orderReducers";
import cogoToast from "cogo-toast";
import { setCartItemstoVerified } from "../slices/cart-slice";
import { updateProductsQtyinCart } from "../slices/product-slice";
const API_URL = process.env.REACT_APP_API_HOST;

export const createOrder = (order) => async (dispatch) => {
  try {
    dispatch(createOrderRequest());
    const token = localStorage.getItem("xat");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    };
    axios
      .post(`${API_URL}/orders/`, order, config)
      .then((res) => {
        dispatch(createOrderSuccess(res.data));
        setTimeout(() => {
          dispatch(setRedirect());
        }, 3000);
      })
      .catch((err) => {
        cogoToast.error(err.message, { position: "bottom-left" });
        dispatch(createOrderFail(err.response.data));
      });
  } catch (error) {
    cogoToast.error(error.response.data, { position: "bottom-left" });
    createOrderFail(error.response.data);
  }
};

export const createMultipleOrder = (order) => async (dispatch) => {
  try {
    dispatch(createOrderRequest());
    const token = localStorage.getItem("xat");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    };
    axios
      .post(`${API_URL}/orders/multiple-orders/`, order, config)
      .then((res) => {
        dispatch(createOrderSuccess(res.data));
        setTimeout(() => {
          dispatch(setRedirect());
        }, 3000);
      })
      .catch((err) => {
        cogoToast.error(err.message, { position: "bottom-left" });
        dispatch(createOrderFail(err.response.data));
      });
  } catch (error) {
    cogoToast.error(error.response.data, { position: "bottom-left" });
    createOrderFail(error.response.data);
  }
};

export const addRetailerOrder = (order) => async (dispatch) => {
  try {
    dispatch(createOrderRequest());
    const token = localStorage.getItem("xat");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    };
    axios
      .post(`${API_URL}/orders/retailer-orders/`, order, config)
      .then((res) => {
        dispatch(createOrderSuccess(res.data));
        setTimeout(() => {
          dispatch(setRedirect());
        }, 3000);
      })
      .catch((err) => {
        cogoToast.error(err.message, { position: "bottom-left" });
        dispatch(createOrderFail(err.response.data));
      });
  } catch (error) {
    cogoToast.error(error.response.data, { position: "bottom-left" });
    createOrderFail(error.response.data);
  }
};
export const saveRetailerOrderTemp = (tempOrder) => async (dispatch) => {
  try {
    dispatch(createOrderSuccess(tempOrder));

  } catch (error) {

  }
};

export const createOrderGuest = (order) => async (dispatch) => {
  try {
    dispatch(createOrderRequest());
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    axios
      .post(`${API_URL}/orders/guest`, order, config)
      .then((res) => {
        dispatch(createOrderSuccess(res.data));
        setTimeout(() => {
          dispatch(setRedirect());
        }, 3000);
      })
      .catch((err) => {
        cogoToast.error(err.message, { position: "bottom-left" });
        dispatch(createOrderFail(err.response.data));
      });
  } catch (error) {
    cogoToast.error(error.response.data, { position: "bottom-left" });
    createOrderFail(error.response.data);
  }
};

export const orderPayment = (orderId, secret) => async (dispatch) => {
  try {
    const token = localStorage.getItem("xat");
    axios
      .put(
        `${API_URL}/orders/${orderId}`, {},
        {
          headers: {
            "Content-Type": "application/json",
            "x-auth-token": token,
            "x-client-secret": secret,
          },
        }
      )
      .then((res) => {
        dispatch(orderPaymentSuccess(res.data));
      })
      .catch((err) => {
        cogoToast.error(err.message, { position: "bottom-left" });
      });
  } catch (error) {
    dispatch({
      type: "ORDER_PAYMENT_FAIL",
      payload: error,
    });

    cogoToast.error(error.response.data.message, { position: "top-center" });
  }
};

export const myOrders = () => async (dispatch) => {
  try {
    const token = localStorage.getItem("xat");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    };

    dispatch(getMyOrdersRequest());

    await axios.get(`${API_URL}/orders/customer-order`, config).then((res) => {
      dispatch(getMyOrdersSuccess(res.data));
    });
  } catch (error) {
    cogoToast.error(error.response.data, { position: "bottom-left" });
  }
};


export const verifyCart = (cart) => async (dispatch) => {
  try {
    const token = localStorage.getItem("xat");
    const config = {
      headers: {
        "Content-Type": "application/json",
        "x-auth-token": token,
      },
    };
    await axios.post(`${API_URL}/orders/verify-cart`, { cart }, config).then((res) => {
      // if (res.data.message) {
      //   cogoToast.info(res.data.message, { position: "bottom-left" });
      // }
      dispatch(setCartItemstoVerified(res.data.verifiedCart));
      dispatch(updateProductsQtyinCart(res.data.verifiedCart));
    });
  } catch (error) {
    console.log(error);
  }
};

export const resetTheOrder = () => async (dispatch) => {
  dispatch(resetOrder());
};
