import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useState } from "react";
import { AiOutlineArrowDown, AiOutlineArrowUp } from "react-icons/ai";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";

const ShopCategoriesFilter = ({ data, title, setFilter, filter }) => {
  const MotionButton = motion.button;
  const [openCategories, setOpenCategories] = useState(false);
  const { models } = useSelector((state) => state.models);
  const obj = {
    Categories: "category",
    Brands: "brand",
    Models: "models",
    Carriers: "carrier",

  };
  const RenderSubMenuModel = ({ dataList, titleName }) => {
    return (
      <ul className="mt-2 ml-2">
        {dataList && dataList.map((category) => (
          <li
            key={`${category.id}-${titleName}`}
            className="ms-3 pb-0 ml-2"
            onClick={(e) => {
              e.stopPropagation();
              if (filter[obj[titleName]].includes(category._id)) {
                setFilter((prev) => ({
                  ...prev,
                  [obj[titleName]]: prev[obj[titleName]].filter(
                    (item) => item !== category._id
                  ),
                }));
              } else {
                setFilter((prev) => ({
                  ...prev,
                  [obj[titleName]]: [...prev[obj[titleName]], category._id],
                }));

              }
            }}
          >

            <Link
              className={`${filter[obj[titleName]].includes(category._id) && "active"
                }`}
            >
              <span className={`checkmark `} />
              {category.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  };
  const RenderSubMenu = ({ data }) => {
    return (
      <ul className="mt-2">
        {data && data.map((category) => (
          <li
            key={category.id}
            className="ms-3 pb-0"
            onClick={() => {
              if (filter[obj[title]].includes(category?._id)) {
                setFilter((prev) => ({
                  ...prev,
                  [obj[title]]: prev[obj[title]].filter(
                    (item) => item !== category?._id
                  ),
                }));
              } else {
                setFilter((prev) => ({
                  ...prev,
                  [obj[title]]: [...prev[obj[title]], category?._id],
                }));
              }
            }}
          >

            <Link
              className={`${filter[obj[title]].includes(category?._id) && "active"
                }`}
            >
              <span className={`checkmark `} />
              {category.name}
            </Link>
            {title === "Brands" && filter[obj[title]].includes(category?._id) && <RenderSubMenuModel dataList={models.filter(model => model.brand?._id === category?._id)} titleName="Models" />}
          </li>
        ))}
      </ul>
    );
  };

  return (
    <div className="sidebar-widget">
      <h4 className="pro-sidebar-title"> </h4>
      <div className="sidebar-widget-list mt-30">
        <ul>
          <li>
            <div className={`sidebar-widget-list-left`}>
              <MotionButton
                onClick={(e) => {
                  e.preventDefault();
                  setOpenCategories(!openCategories);
                }}
                className="ps-2"
              >
                <p className="mb-0 fs-5">{title}</p>
                <motion.span>
                  {openCategories ? (
                    <AiOutlineArrowUp />
                  ) : (
                    <AiOutlineArrowDown />
                  )}
                </motion.span>
              </MotionButton>
              <AnimatePresence>
                {data && openCategories && (
                  <motion.div
                    initial={{ height: 0, opacity: 0 }}
                    animate={{ height: "auto", opacity: 1 }}
                    exit={{ height: 0, opacity: 0 }}
                    transition={{ duration: 0.2 }}
                    className="submenu-container"
                  >
                    <RenderSubMenu data={data} />
                  </motion.div>
                )}
              </AnimatePresence>
            </div>
          </li>
        </ul>
      </div>
    </div>
  );
};

ShopCategoriesFilter.propTypes = {
  categories: PropTypes.array,
  getSortParams: PropTypes.func,
};

export default ShopCategoriesFilter;
