import axios from 'axios';
import { setNav, loadingNav } from '../slices/nav-slice';

const API_URL = process.env.REACT_APP_API_HOST

export const getNav = () => {
    return async dispatch => {
        try {
            dispatch(loadingNav(true))
            await axios.get(`${API_URL}/nav`).then(
                (res) => {
                    dispatch(setNav(res.data))
                }
            ).catch(
                (err) => {
                    loadingNav(false)
                }
            )
        } catch (error) {
            loadingNav(false)
        }
    }
}

