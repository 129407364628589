// import { Fragment, useState } from "react";
import { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import SEO from "../../components/seo";
import LayoutOne from "../../layouts/LayoutOne";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { deleteAllFromCart, checkoutStore } from "../../store/slices/cart-slice";
import { calculateTaxAmount } from "../../helpers/product";
import { addToWishlist } from "../../store/slices/wishlist-slice";
import { saveRetailerOrderTemp, verifyCart } from "../../store/actions/orderActions";
import RetailerCartSummaryList from "./RetailerCartSummaryList";
import ConsumerCartSummaryList from "./ConsumerCartSummaryList";
import axios from "axios";
import { productPriceAfterDiscount } from "../../helpers/cartCalculations";
import cogoToast from "cogo-toast";

const RetailerCart = () => {
    const navigate = useNavigate();
    const [cartTotalPrice, setCartTotalPrice] = useState(0)
    const [cartTotalDiscountedPrice, setCartTotalDiscountedPrice] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [tierDisount, setTierDisount] = useState(0)
    const dispatch = useDispatch();
    let { pathname } = useLocation();

    const currency = useSelector((state) => state.currency);
    const { cartItems, retailerCart, storesToCheckout } = useSelector((state) => state.cart);
    const { wishlistItems } = useSelector((state) => state.wishlist);
    const { taxes } = useSelector((state) => state.tax);
    const { user } = useSelector((state) => state.auth);
    const [selectCarts, setSelectCarts] = useState([]);



    let userType = user?.type || "Consumer";

    // eslint-disable-next-line
    const Tax = taxes?.find((i) => {
        return i;
    });

    useEffect(() => {
        dispatch(verifyCart(cartItems));
        if (userType === 'Retailer') {
            setSelectCarts(retailerCart.map((item) => item.storeId));
            const selected = document.getElementsByClassName("cart-checkbox");
            for (let i = 0; i < selected.length; i++) {
                selected[i].checked = true;
            }
        }
        // eslint-disable-next-line
    }, [])

    const handlePayatStore = () => {

        const stores = retailerCart.filter((item) => storesToCheckout.includes(item.storeId)).map((item) =>
        ({
            storeId: item.storeId,
            cartItems: item.cartItems,
            totalItemsPrice: item.cartItems.reduce((total, item) => total + productPriceAfterDiscount(item, user, currency) * item.quantity, 0),
        })
        );
        const orderData = {
            stores: stores,
            itemsPrice: cartTotalPrice,
            discount: totalDiscount.toFixed(2),
            isPaid: false,
            payAtStore: true
        }

        dispatch(
            saveRetailerOrderTemp({
                stores: stores,
                isPaid: false,
            }));

        try {
            const config = {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': user.token
                }

            }
            axios.post(`${process.env.REACT_APP_API_HOST}/orders/pay-at-store`, orderData, config)
                .then((res) => {
                    if (res.status === 201) {
                        cogoToast.success("Order placed successfully", { position: 'top-center' });
                        navigate("/thankyou")
                    }
                })
                .catch((error) => {
                    console.log(error);
                })
        }

        catch (error) {
            console.log(error);
        }

    }

    const handleSelectCarts = (e) => {
        const { name, checked } = e.target;
        if (checked) {
            setSelectCarts([...selectCarts, name]);
            checkoutStore()
        } else {
            setSelectCarts(selectCarts.filter((item) => item !== name));
        }
    };

    const selectAllStores = (e) => {
        const { checked } = e.target;
        if (checked) {
            setSelectCarts(retailerCart.map((item) => item.storeId));
            const selected = document.getElementsByClassName("cart-checkbox");
            for (let i = 0; i < selected.length; i++) {
                selected[i].checked = true;
            }
        } else {
            setSelectCarts([]);
            const deselect = document.getElementsByClassName("cart-checkbox");
            for (let i = 0; i < deselect.length; i++) {
                deselect[i].checked = false;
            }
        }
    };


    useEffect(() => {
        dispatch(checkoutStore(selectCarts));
        // eslint-disable-next-line
    }, [selectCarts]);

    const selectedCartItemsArray = userType === "Retailer" ? selectCarts.map((cartId) => {
        return retailerCart?.find((item) => item.storeId === cartId)?.cartItems;
    }
    ) : cartItems;

    const selectedCartItems = selectedCartItemsArray.flat();

    const handleCartTotal = () => {
        let total = 0;
        selectedCartItems.forEach((item) => {
            total += item?.quantity * (userType === "Retailer" ? (item?.retailerPrice || item?.price) : item?.price);
        });
        return total?.toFixed(2);
    }


    const handleCartTotalDiscount = () => {
        let total = 0;
        cartItems.forEach((item) => {
            const finalProductPrice = user?.type === "Retailer" ? item?.retailerPrice : user?.type === "Dropshipper" ? item?.dropshipperPrice : item?.price;
            const finalDiscount = user?.type === "Retailer" ? item?.retailerDiscount : user?.type === "Dropshipper" ? item?.dropshipperDiscount : item?.consumerDiscount;

            if (finalProductPrice > finalDiscount) {
                total += (finalProductPrice - finalDiscount) * item.quantity;
            }
        });
        return total?.toFixed(2);
    }

    const handleCartTotalDiscountedPrice = () => {
        let total = 0;
        selectedCartItems.forEach((item) => {
            total += item.quantity * (item.retailerPrice < item.retailerDiscount ? item.retailerPrice : item.retailerDiscount);
        });

        return total.toFixed(2);


        // const totalPriceWithTax = total +
        //     (
        //         +calculateTaxAmount(
        //             cartTotalDiscountedPrice > 0 ? cartTotalDiscountedPrice : cartTotalPrice,
        //             userType === "Retailer" || userType === "Dropshipper" ? 0 :
        //                 +Tax?.tax > 0 ? +Tax?.tax : 0
        //         )?.toFixed(2))
        // return totalPriceWithTax?.toFixed(2);
    }

    const handleCartTotalTax = () => {
        let total = 0;
        selectedCartItems.forEach((item) => {
            total += item.quantity * item.tax;
        });
        return total?.toFixed(2);
    }


    return (
        <Fragment>
            <SEO
                titleTemplate="Cart"
                description="Cart page of Remax Wireless."
            />

            <LayoutOne headerTop="visible" headerContainerClass="container-fluid" headerPaddingClass="header-padding-1">
                {/* breadcrumb */}
                <Breadcrumb
                    pages={[
                        { label: "Home", path: process.env.PUBLIC_URL + "/" },
                        { label: "Cart", path: process.env.PUBLIC_URL + pathname }
                    ]}
                />

                <div className="cart-main-area pt-90 pb-100">
                    <h3 className="cart-page-title text-center mb-4">Your Stores Cart Summary</h3>
                    <div className="container-fluid">
                        {cartItems && cartItems.length >= 1 ? (
                            <Fragment>
                                <div className="row">
                                    <div className="col-1"></div>
                                    <div className="col-7">
                                        <div className="row  p-3">
                                            {userType === "Retailer" && <div className="d-flex">
                                                {
                                                    (retailerCart?.length === selectCarts.length) ? (<div className="input-group"><input type="checkbox" name="selectAll" id="selectAll" checked={true} onChange={(e) => { selectAllStores(e) }} />
                                                        <label className="form-check-label mx-2" htmlFor="selectAll">Deselect All</label>
                                                    </div>) : (<><input type="checkbox" name="selectAll" id="selectAll" onChange={(e) => { selectAllStores(e) }} /><label className="form-check-label mx-2" htmlFor="selectAll">Select All</label></>)
                                                }
                                            </div>}
                                            <div className="col-lg-12">
                                                <div className="billing-select mb-20">
                                                    <div className="select-style">
                                                        {userType === "Retailer" ?
                                                            <RetailerCartSummaryList
                                                                retailerCart={retailerCart}
                                                                handleSelectCarts={handleSelectCarts}
                                                                tierDisount={tierDisount}
                                                                setTierDisount={setTierDisount}
                                                                cartTotalDiscountedPrice={cartTotalDiscountedPrice}
                                                                setCartTotalDiscountedPrice={setCartTotalDiscountedPrice}
                                                                cartTotalPrice={cartTotalPrice}
                                                                setCartTotalPrice={setCartTotalPrice}
                                                                totalDiscount={totalDiscount}
                                                                setTotalDiscount={setTotalDiscount}
                                                                wishlistItems={wishlistItems}
                                                                addToWishlist={addToWishlist}
                                                            /> :
                                                            <ConsumerCartSummaryList
                                                                cartItems={cartItems}
                                                                handleSelectCarts={handleSelectCarts}
                                                                setTierDisount={setTierDisount}
                                                                tierDisount={tierDisount}
                                                                cartTotalDiscountedPrice={cartTotalDiscountedPrice}
                                                                setCartTotalDiscountedPrice={setCartTotalDiscountedPrice}
                                                                cartTotalPrice={cartTotalPrice}
                                                                setCartTotalPrice={setCartTotalPrice}
                                                                totalDiscount={totalDiscount}
                                                                setTotalDiscount={setTotalDiscount}
                                                                wishlistItems={wishlistItems}
                                                                addToWishlist={addToWishlist}
                                                            />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="row">
                                            <div className="col-lg-12 d-flex justify-content-between">
                                                <div className="cart-shiping-update-wrapper">

                                                    <div className="cart-clear">
                                                        <button onClick={() => dispatch(deleteAllFromCart())}>
                                                            Clear Shopping Cart
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="cart-shiping-update-wrapper">
                                                    <div className="cart-shiping-update">
                                                        <Link
                                                            to={process.env.PUBLIC_URL + "/shop"}
                                                        >
                                                            Continue Shopping
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-lg-3 col-md-12">
                                        <div className="grand-totall">
                                            <div className="title-wrap">
                                                <h4 className="cart-bottom-title section-bg-gary-cart">
                                                    Cart Total
                                                </h4>
                                            </div>
                                            <h5>
                                                Total Products
                                                <span>

                                                    {currency.currencySymbol + handleCartTotal()}
                                                </span>
                                            </h5>
                                            {
                                                totalDiscount > 0 ? (
                                                    <h5>
                                                        Discount
                                                        <span>
                                                            {currency.currencySymbol + handleCartTotalDiscount()}
                                                        </span>
                                                    </h5>
                                                ) : (null)
                                            }
                                            {
                                                cartTotalDiscountedPrice > 0 ? (
                                                    <h5>
                                                        After Discount
                                                        <span>
                                                            {currency.currencySymbol + handleCartTotalDiscountedPrice()}
                                                        </span>
                                                    </h5>) : (null)
                                            }
                                            {
                                                tierDisount > 0 ? (
                                                    <h5>
                                                        Tier Discount
                                                        <span>
                                                            {currency.currencySymbol + tierDisount?.toFixed(2)}
                                                        </span>
                                                    </h5>
                                                ) : (null)
                                            }
                                            {
                                                userType !== "Retailer" && userType !== "Dropshipper" ? (
                                                    <h5>
                                                        Tax
                                                        <span>
                                                            {
                                                                cartTotalDiscountedPrice > 0 ? (
                                                                    currency.currencySymbol +
                                                                    calculateTaxAmount(
                                                                        cartTotalDiscountedPrice,
                                                                        +Tax?.tax > 0 ? +Tax?.tax : 0
                                                                    )?.toFixed(2)
                                                                ) : (
                                                                    currency.currencySymbol +
                                                                    calculateTaxAmount(
                                                                        cartTotalPrice,
                                                                        +Tax?.tax > 0 ? +Tax?.tax : 0
                                                                    )?.toFixed(2)
                                                                )
                                                            }
                                                        </span>
                                                    </h5>
                                                ) : (null)
                                            }

                                            <h4 className="grand-totall-title">
                                                Grand Total
                                                <span>
                                                    {
                                                        currency.currencySymbol + handleCartTotalDiscountedPrice()
                                                    }
                                                    {/* {
                                                        cartTotalDiscountedPrice > 0 ? (
                                                            currency.currencySymbol + ((+cartTotalDiscountedPrice?.toFixed(2)) + (+calculateTaxAmount(cartTotalDiscountedPrice, +Tax?.tax > 0 ? +Tax?.tax : 0)?.toFixed(2)))?.toFixed
                                                                (2)

                                                        ) : (
                                                            currency.currencySymbol + ((+cartTotalPrice?.toFixed(2)) + (+calculateTaxAmount(cartTotalPrice, +Tax?.tax > 0 ? +Tax?.tax : 0)?.toFixed(2)))?.toFixed
                                                                (2)
                                                        )
                                                    } */}
                                                </span>
                                            </h4>
                                            <Link to={process.env.PUBLIC_URL + "/checkout"}>
                                                Proceed to Checkout
                                            </Link>
                                            {
                                                user.payAtStore && (
                                                    <div className="pay-at-store" onClick={handlePayatStore}>
                                                        <button>
                                                            Pay at Store
                                                        </button>
                                                    </div>
                                                )
                                            }
                                        </div>

                                    </div>
                                    <div className="col-1"></div>
                                </div>
                            </Fragment>
                        ) : (
                            <div className="row">
                                <div className="col-lg-12">
                                    <div className="item-empty-area text-center">
                                        <div className="item-empty-area__icon mb-30">
                                            <i className="pe-7s-cart"></i>
                                        </div>
                                        <div className="item-empty-area__text">
                                            No items found in cart <br />
                                            <Link to={process.env.PUBLIC_URL + "/shop"}>
                                                Shop Now
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </LayoutOne>
        </Fragment>
    );
};

export default RetailerCart;
