import PropTypes from "prop-types";
import { useState } from "react";
import clsx from "clsx";
import Logo from "../../components/header/Logo";
import NavMenu from "../../components/header/NavMenu";
import IconGroup from "../../components/header/IconGroup";
import MobileMenu from "../../components/header/MobileMenu";
import MegaMenu from "../../components/header/MegaMenu";
// import HeaderTop from "../../components/header/HeaderTop";
import { useNavigate, useSearchParams } from "react-router-dom";
// import { useDispatch } from "react-redux";
// import { setSearchedProducts } from "../../store/slices/product-slice";
// import PulseLoader from "../../components/ImageInputFiled/PulseLoader";

const HeaderOne = ({
  // layout,
  // top,
  // borderStyle,
  headerPaddingClass,
  headerPositionClass,
  headerBgClass,
}) => {
  // eslint-disable-next-line
  const [SearchUrl, setSearchUrl] = useSearchParams();
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  // const [headerTop, setHeaderTop] = useState(0);

  // useEffect(() => {
  //   const header = document.querySelector(".sticky-bar");
  //   setHeaderTop(header.offsetTop);
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  // const handleScroll = () => {
  //   setScroll(window.scrollY);
  // };

  const handleChange = (e) => {
    e.preventDefault();
    const value = e.target.value;
    setSearchUrl({ search: value });
    setLoading(true);
  };

  return (
    <header
      className={clsx(
        "header-area clearfix",
        headerBgClass,
        headerPositionClass
      )}
    >
      <div
        className={clsx(
          headerPaddingClass,
          "sticky-bar header-res-padding clearfix"
          // scroll > headerTop && "stick"
        )}
        style={{
          backgroundColor: "#333545",
          paddingTop: "10px",
          paddingBottom: "10px",
        }}
      >
        {/* <div className={layout === "container-fluid" ? layout : "container"}> */}
        <div className="container">
          <div className="row">
            <div className="col-xl-2 col-lg-2 col-md-6 col-4">
              {/* header logo */}
              <Logo imageUrl="/assets/img/logo/logo.png" logoClass="logo" />
            </div>
            <div className="col-xl-7 col-lg-7 d-none d-lg-flex align-items-center justify-content">
              {/* Nav menu */}

              <div className="d-none d-lg-block" style={{ width: "50%" }}>
                {/* <button className="search-active" >
                  <i className="pe-7s-search" />
                </button> */}
                <div className="">
                  <form className="d-flex">
                    <input
                      type="text"
                      placeholder="Search"
                      onChange={handleChange}
                      name="search"
                      className="search-style"
                      onFocus={() => navigate("/shop")}
                    />
                    <button
                      className="button-search search-button-style"
                      onClick={(e) => {
                        e.preventDefault();
                      }}
                    >
                      <i className="pe-7s-search" />
                    </button>
                  </form>
                </div>
              </div>
            </div>

            <div className="col-xl-3 col-lg-3 col-md-6 col-8 d-lg-flex d-flex align-items-center justify-content-end">
              {/* Icon group */}
              <IconGroup />
            </div>
          </div>
        </div>

        {/* mobile menu */}
        <MobileMenu />
      </div>
      <div className="d-none d-lg-block header-menu-style">
        <div className="container">
          {/* <NavMenu /> */}
          <MegaMenu />
        </div>
      </div>
      <div></div>
    </header>
  );
};

HeaderOne.propTypes = {
  borderStyle: PropTypes.string,
  headerPaddingClass: PropTypes.string,
  headerPositionClass: PropTypes.string,
  layout: PropTypes.string,
  top: PropTypes.string,
};

export default HeaderOne;
