import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MobileNavMenu = () => {
  const { nav } = useSelector((state) => state.navbar);

  const data = nav?.map((brand) => {
    return {
      brand: brand.name,
      slug: brand.slug,
      categories: brand.brands.map((category) => {
        return {
          name: category.name,
          slug: category.slug,
          items: category.models.map((item) => {
            return {
              name: item.name,
              slug: item.slug,
            };
          }),
        };
      }),
    };
  });

  const renderMenu = () => {
    return (
      <ul>
        {data.map((brand, index) => (
          <li key={index} className="menu-item-has-children">
            <div
              style={{
                fontWeight: "600",
                display: "block",
                padding: "10px 0",
                color: "#111",
                textTransform: "uppercase",
              }}
            >
              <Link to={`/shop/${brand.slug}`}>
                {brand.brand}
              </Link>
            </div>
            {brand.categories && (
              <ul className="sub-menu">
                {brand.categories.map((category, categoryIndex) => (
                  <li key={categoryIndex} className="menu-item-has-children">
                    <div>
                      <Link to={`/shop/${brand.slug}/${category.slug}`}>
                        {category.name}
                      </Link>
                    </div>
                    {category.items && (
                      <ul className="sub-menu">
                        {category.items.map((item, itemIndex) => (
                          <li key={itemIndex}>
                            <Link to={`/shop/${brand.slug}/${category.slug}/${item.slug}`}>
                              {item.name}
                            </Link>
                          </li>
                        ))}
                      </ul>
                    )}
                  </li>
                ))}

              </ul>
            )}
          </li>
        ))}
        <li className="mobile-menu-item">
          <Link to="/clearance">Clearance</Link>
        </li>
        <li className="mobile-menu-item">
          <Link to="/contact">Contact Us</Link>
        </li>
      </ul>
    );
  };

  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      {data && renderMenu()}
    </nav>
  );
};

export default MobileNavMenu;
