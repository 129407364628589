import PropTypes from "prop-types";
import { Fragment, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import clsx from "clsx";
import { getPercentOff } from "../../helpers/product";
import Rating from "./sub-components/ProductRating";
import ProductModal from "./ProductModal";
import { addToCart, addToRetailerCart, decreaseQuantity, decreaseRetailerQuantity, increaseQuantity, increaseRetailerQuantity, increaseStockQuantity } from "../../store/slices/cart-slice";
import { addToWishlist } from "../../store/slices/wishlist-slice";
import ProductPrice from "../../wrappers/product/ProductPrice";
import cogoToast from "cogo-toast";

const ProductGridListSingle = ({
  product,
  currency,
  cartItem,
  wishlistItem,
  compareItem,
  spaceBottomClass
}) => {

  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(cartItem?.quantity ? cartItem?.quantity.toString() : "1");

  const [modalShow, setModalShow] = useState(false);
  const { user } = useSelector((state) => state.auth);

  const finalProductPrice = user?.type === "Retailer" ? product?.retailerPrice : user?.type === "Dropshipper" ? product?.dropshipperPrice : product?.price;
  const finalDiscount = user?.type === "Retailer" ? product?.retailerDiscount : user?.type === "Dropshipper" ? product?.dropshipperDiscount : product?.consumerDiscount;
  const percentOff = getPercentOff(finalProductPrice, finalDiscount)
  const finalDiscountedPrice = +(finalDiscount * currency.currencyRate)?.toFixed(2);

  const typingTimeoutRef = useRef(null); // Use useRef to persist timeout across renders

  const handleQuantityChange = (e) => {
    const value = e.target.value;

    // Ensure input is a valid number or empty string (for editing)
    if (/^\d*$/.test(value)) {
      setQuantity(value);

      // Clear existing debounce timeout
      clearTimeout(typingTimeoutRef.current);

      // Set a new timeout to debounce the update logic
      typingTimeoutRef.current = setTimeout(() => {
        if (user?.type === "Retailer" && cartItem?.stock >= parseInt(value)) {
          dispatch(increaseStockQuantity({ ...cartItem, quantity: parseInt(value) }));
        } else if (cartItem?.stock < parseInt(value)) {
          // Show an error message if quantity exceeds stock
          cogoToast.error(`Quantity exceeds available stock! Only ${cartItem?.stock} left in stock.`);
          setQuantity(cartItem?.stock.toString()); // Reset to stock limit
          dispatch(increaseStockQuantity({ ...cartItem, quantity: parseInt(cartItem?.stock) }));
        }
      }, 2000);
    } else {
      cogoToast.error("Please enter a valid number!"); // Show error for invalid input
    }
  };


  return (
    <Fragment>
      <div className={clsx("product-wrap", spaceBottomClass)}>
        <div className="product-img">
          <Link to={process.env.PUBLIC_URL + "/product/" + product?._id}>
            {
              product?.images?.length < 1 ? (
                <img
                  className="default-img"
                  src={process.env.PUBLIC_URL + "/assets/img/empty.png"}
                  alt=""
                />
              ) : (
                <>
                  <img
                    className="default-img"
                    src={process.env.PUBLIC_URL + product?.images[0]}
                    alt=""
                  />
                  {product?.images?.length > 1 ? (
                    <img
                      className="hover-img"
                      src={process.env.PUBLIC_URL + product?.images[1]}
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                </>
              )

            }

          </Link>
          {finalDiscount || product?.new ? (
            <div className="product-img-badges">
              {percentOff ? (
                <span className="pink">-{percentOff}%</span>
              ) : (
                ""
              )}
              {product?.new ? <span className="purple">New</span> : ""}
            </div>
          ) : (
            ""
          )}

          <div className="product-action">
            <div className="pro-same-action pro-wishlist">
              <button
                className={wishlistItem !== undefined ? "active" : ""}
                disabled={wishlistItem !== undefined}
                title={
                  wishlistItem !== undefined
                    ? "Added to wishlist"
                    : "Add to wishlist"
                }
                onClick={() => dispatch(addToWishlist(product))}
              >
                <i className="pe-7s-like" />
              </button>
            </div>
            <div className="pro-same-action pro-cart">
              {product?.affiliateLink ? (
                <a
                  href={product?.affiliateLink}
                  rel="noopener noreferrer"
                  target="_blank"
                >
                  {" "}
                  Buy now{" "}
                </a>
              ) : product?.variation && product?.variation.length >= 1 ? (
                <Link to={`${process.env.PUBLIC_URL}/product/${product?._id}`}>
                  Select Option
                </Link>
              ) : product?.stock && product?.stock > 0 ? (

                cartItem !== undefined && cartItem.quantity > 0 ? (
                  <>
                    <div className="cart-qty-btn">
                      <button
                        className="dec qntybutton"
                        onClick={() => {
                          if (cartItem !== undefined &&
                            cartItem.quantity > 1
                          ) {
                            user?.type === "Retailer" ? dispatch(decreaseRetailerQuantity(cartItem)) : dispatch(decreaseQuantity(cartItem))
                          }
                        }
                        }
                      >
                        -
                      </button>
                      <input
                        className="cart-qty-btn-box"
                        type="text"
                        value={quantity}
                        onChange={handleQuantityChange}
                      />
                      <button
                        className="inc qntybutton"
                        onClick={() => {
                          if (cartItem !== undefined &&
                            cartItem.quantity < cartItem?.stock
                          ) {
                            user?.type === "Retailer" ? dispatch(increaseRetailerQuantity(cartItem)) : dispatch(increaseQuantity(cartItem))
                          }
                        }
                        }
                        disabled={
                          cartItem !== undefined &&
                          cartItem.quantity >= cartItem?.stock
                        }
                      >
                        +
                      </button>
                    </div>
                  </>
                )
                  : (
                    <>
                      <button
                        onClick={() => {
                          user && user.type === "Retailer" ? dispatch(addToRetailerCart(product)) : dispatch(addToCart(product))
                        }}
                        className={
                          cartItem !== undefined && cartItem.quantity > 0
                            ? "active"
                            : ""
                        }
                        // disabled={cartItem !== undefined && cartItem.quantity > 0}
                        title={
                          cartItem !== undefined ? "Added to cart" : "Add to cart"
                        }
                      >
                        {" "}
                        <i className="pe-7s-cart"></i>{" "}
                        {cartItem !== undefined && cartItem.quantity > 0
                          ? "Added"
                          : "Add to cart"}
                      </button>
                    </>
                  )

              ) : (
                <button disabled className="active">
                  Out of Stock
                </button>
              )}
            </div>
            <div className="pro-same-action pro-quickview">
              <button onClick={() => setModalShow(true)} title="Quick View">
                <i className="pe-7s-look" />
              </button>
            </div>
          </div>
        </div >
        <div className="product-content text-center">
          <h3>
            <Link to={`${process.env.PUBLIC_URL}/product/${product?._id}`}>
              {product?.name}
            </Link>
          </h3>
          {product?.rating && product?.rating > 0 ? (
            <div className="product-rating">
              <Rating ratingValue={product?.rating} />
            </div>
          ) : (
            ""
          )}

          <ProductPrice
            finalProductPrice={finalProductPrice}
            finalDiscount={finalDiscount}
            currency={currency}
          />
        </div>
      </div >
      {/* <div div className="shop-list-wrap mb-30" >
        <div className="row">
          <div className="col-xl-4 col-md-5 col-sm-6">
            <div className="product-list-image-wrap">
              <div className="product-img">
                <Link to={process.env.PUBLIC_URL + "/product/" + product?.slug}>
                  <img
                    className="default-img img-fluid"
                    src={process.env.PUBLIC_URL + product?.images[0]}
                    alt=""
                  />
                  {product?.images?.length > 1 ? (
                    <img
                      className="hover-img img-fluid"
                      src={process.env.PUBLIC_URL + product?.images[1]}
                      alt=""
                    />
                  ) : (
                    ""
                  )}
                </Link>
                {product?.discount || product?.new ? (
                  <div className="product-img-badges">
                    {product?.discount ? (
                      <span className="pink">-{product?.discount}%</span>
                    ) : (
                      ""
                    )}
                    {product?.new ? <span className="purple">New</span> : ""}
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
          <div className="col-xl-8 col-md-7 col-sm-6">
            <div className="shop-list-content">
              <h3>
                <Link to={process.env.PUBLIC_URL + "/product/" + product?.slug}>
                  {product?.name}
                </Link>
              </h3>
              <div className="product-list-price">
                {
                  isAuthenticated && (<><div className="product-price">
                    {discountedPrice !== null ? (
                      <Fragment>
                        <span>{currency.currencySymbol + finalDiscountedPrice}</span>{" "}
                        <span className="old">
                          {currency.currencySymbol + finalProductPrice}
                        </span>
                      </Fragment>
                    ) : (
                      <span>{currency.currencySymbol + finalProductPrice} </span>
                    )}
                  </div></>)
                }
              </div>
              {product?.rating && product?.rating > 0 ? (
                <div className="rating-review">
                  <div className="product-list-rating">
                    <Rating ratingValue={product?.rating} />
                  </div>
                </div>
              ) : (
                ""
              )}
              {product?.shortDescription ? (
                <p>{product?.shortDescription}</p>
              ) : (
                ""
              )}

              <div className="shop-list-actions d-flex align-items-center">
                <div className="shop-list-btn btn-hover">
                  {
                    isAuthenticated ? (<>
                      {product?.affiliateLink ? (
                        <a
                          href={product?.affiliateLink}
                          rel="noopener noreferrer"
                          target="_blank"
                        >
                          {" "}
                          Buy now{" "}
                        </a>
                      ) : product?.variation && product?.variation.length >= 1 ? (
                        <Link
                          to={`${process.env.PUBLIC_URL}/product/${product?._id}`}
                        >
                          Select Option
                        </Link>
                      ) : product?.stock && product?.stock > 0 ? (
                        <button
                          onClick={() => dispatch(addToCart(product))}
                          className={
                            cartItem !== undefined && cartItem.quantity > 0
                              ? "active"
                              : ""
                          }
                          disabled={
                            cartItem !== undefined && cartItem.quantity > 0
                          }
                          title={
                            cartItem !== undefined
                              ? "Added to cart"
                              : "Add to cart"
                          }
                        >
                          {" "}
                          <i className="pe-7s-cart"></i>{" "}
                          {cartItem !== undefined && cartItem.quantity > 0
                            ? "Added"
                            : "Add to cart"}
                        </button>
                      ) : (
                        <button disabled className="active">
                          2  Out of Stock
                        </button>
                      )}
                    </>) : (<>
                      <div className="pro-same-action pro-cart"><Link to="/login-register">Login to buy</Link></div>
                    </>)
                  }

                </div>

                <div className="shop-list-wishlist ml-10">
                  <button
                    className={wishlistItem !== undefined ? "active" : ""}
                    disabled={wishlistItem !== undefined}
                    title={
                      wishlistItem !== undefined
                        ? "Added to wishlist"
                        : "Add to wishlist"
                    }
                    onClick={() => dispatch(addToWishlist(product))}
                  >
                    <i className="pe-7s-like" />
                  </button>
                </div>
                <div className="shop-list-compare ml-10">
                  <button
                    className={compareItem !== undefined ? "active" : ""}
                    disabled={compareItem !== undefined}
                    title={
                      compareItem !== undefined
                        ? "Added to compare"
                        : "Add to compare"
                    }
                    onClick={() => dispatch(addToCompare(product))}
                  >
                    <i className="pe-7s-shuffle" />
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div > */}
      {/* product modal */}
      < ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        discountedPrice={finalDiscount}
        finalProductPrice={finalProductPrice}
        finalDiscountedPrice={finalDiscountedPrice}
        wishlistItem={wishlistItem}
        compareItem={compareItem}
      />
    </Fragment >
  );
};

ProductGridListSingle.propTypes = {
  cartItem: PropTypes.shape({}),
  compareItem: PropTypes.shape({}),
  currency: PropTypes.shape({}),
  product: PropTypes.shape({}),
  spaceBottomClass: PropTypes.string,
  wishlistItem: PropTypes.shape({})
};

export default ProductGridListSingle;
