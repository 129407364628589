import { Suspense, lazy, useEffect } from "react";
import ScrollToTop from "./helpers/scroll-top";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchMoreFilteredProduct, fetchProducts } from "./store/actions/productActions";
import { fetchCategories } from "./store/actions/categoryActions";
import { fetchSubCategories } from "./store/actions/subcategoryActions";
import { fetchBrands } from "./store/actions/brandActions";
import { fetchCarriers } from "./store/actions/carrierActions";
import { fetchModels } from "./store/actions/modelActions";
import { fetchDesigns } from "./store/actions/designActions";
import Brands from "./pages/other/Brands";
import "./App.css";
import Products from "./pages/shop/Products";
import { getBanners } from "./store/actions/bannerActions";
import Filtered from "./pages/shop/Filtered";
import { getAddress } from "./store/actions/addressAction";
import StoreLocation from "./pages/other/StoreLocation";
import MyOrders from "./pages/other/MyOrders";
import { fetchTaxes } from "./store/actions/taxActions";
import ReturnPolicy from "./pages/other/ReturnPolicy";
import ShippingPolicy from "./pages/other/ShippingPolicy";
import PrivacyPolicy from "./pages/other/PrivacyPolicy";
import VerifyEmail from "./pages/other/VerifyEmail";
import { Toaster } from "react-hot-toast";
import { getAds } from "./store/actions/adsActions";
import { setSelectedStore, setStoreCart } from "./store/slices/cart-slice";
import RetailerCart from "./pages/other/RetailerCart";
import { getNav } from "./store/actions/navAction";

// home pages
const RemaxHome = lazy(() => import("./pages/home/RemaxHome"));
// shop pages
const ShopGridFullWidth = lazy(() => import("./pages/shop/ShopGridFullWidth"));
const Payment = lazy(() => import("./pages/other/Payment"));
// product pages
const ProductTabLeft = lazy(() => import("./pages/shop-product/ProductTabLeft"));
// blog pages
const BlogStandard = lazy(() => import("./pages/blog/BlogStandard"));
const BlogNoSidebar = lazy(() => import("./pages/blog/BlogNoSidebar"));
const BlogRightSidebar = lazy(() => import("./pages/blog/BlogRightSidebar"));
const BlogDetailsStandard = lazy(() => import("./pages/blog/BlogDetailsStandard"));
// other pages
const About = lazy(() => import("./pages/other/About"));
const Contact = lazy(() => import("./pages/other/Contact"));
const MyAccount = lazy(() => import("./pages/other/MyAccount"));
const Login = lazy(() => import("./pages/other/Login"));
const Register = lazy(() => import("./pages/other/Register"));
const ExistingCustomer = lazy(() => import("./pages/other/ExistingCustomer"));
const SizeGuide = lazy(() => import("./pages/other/SizeGuide"));

const Cart = lazy(() => import("./pages/other/Cart"));
const Wishlist = lazy(() => import("./pages/other/Wishlist"));
const Compare = lazy(() => import("./pages/other/Compare"));
const Checkout = lazy(() => import("./pages/other/Checkout"));
const ThankYou = lazy(() => import("./pages/other/ThankYou"));
const Categories = lazy(() => import("./pages/other/Categories"));
const NewArrival = lazy(() => import("./pages/other/NewArrivals"));
const Clearance = lazy(() => import("./pages/other/Clearance"));
const NotFound = lazy(() => import("./pages/other/NotFound"));

const App = () => {
  const { isAuthenticated } = useSelector((state) => state.auth);
  const { categories } = useSelector((state) => state.category);
  const { subcategories } = useSelector((state) => state.subcategory);
  const { address } = useSelector((state) => state.store);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getNav());
    dispatch(fetchSubCategories());
    dispatch(fetchCategories());
    dispatch(fetchBrands());
    dispatch(fetchCarriers());
    dispatch(fetchModels());
    dispatch(fetchDesigns());
    dispatch(getBanners());
    dispatch(fetchTaxes());
    dispatch(getAds());
    dispatch(fetchMoreFilteredProduct("/?page=1&limit=40"));
    dispatch(fetchProducts());

    if (isAuthenticated) {
      dispatch(getAddress());
    }
  }, [dispatch, isAuthenticated]);

  useEffect(() => {

    if (address.length !== 0) {
      const storeId = localStorage.getItem("std");
      if (storeId) {
        dispatch(setSelectedStore(storeId))
        setStoreCart(storeId)
      }
      else {
        localStorage.setItem("std", address[0]?._id)
        dispatch(setSelectedStore(address[0]?._id))
        setStoreCart(address[0]?._id)
      }
    }
    // eslint-disable-next-line
  }, [])

  return (
    <Router>
      <ScrollToTop>
        <Suspense
          fallback={
            <div className="flone-preloader-wrapper">
              <div className="flone-preloader">
                <span></span>
                <span></span>
              </div>
            </div>
          }
        >
          <Routes>
            {categories.map((category) => (
              <Route
                path={process.env.PUBLIC_URL + `/category/${category.slug}`}
                element={
                  <ShopGridFullWidth
                    category={category.slug}
                    subcategory="all"
                  />
                }
                key={category.id}
              />
            ))}
            {categories.map((category) =>
              subcategories.map((subcategory) => (
                <Route
                  path={
                    process.env.PUBLIC_URL +
                    `/category/${category.slug}/${subcategory.slug}`
                  }
                  element={
                    <ShopGridFullWidth
                      category={category.slug}
                      subcategory={subcategory.slug}
                      metalType="all"
                    />
                  }
                  key={subcategory.id}
                />
              ))
            )}
            {/* {
              categoryData.map((metalType) => (
                <Route
                  path={process.env.PUBLIC_URL + `${metalType.url}`}
                  element={<ShopGridFullWidth metalType={metalType.title} category="all" />}
                  key={metalType.id}
                />
              ))
            } */}

            <Route
              path={process.env.PUBLIC_URL + "/"}
              element={<RemaxHome />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/store-location"}
              element={<StoreLocation />}
            />

            {/* Shop pages */}
            <Route
              path={process.env.PUBLIC_URL + "/shop"}
              element={<ShopGridFullWidth />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/shop/:brand"}
              element={<ShopGridFullWidth />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/shop/:brand/:category"}
              element={<ShopGridFullWidth />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/shop/:brand/:category/:model"}
              element={<ShopGridFullWidth />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/new-arrivals"}
              element={<NewArrival />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/clearance"}
              element={<ShopGridFullWidth clearance={true} />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/products"}
              element={<Products />}
            />

            {/* Shop product pages */}
            <Route
              path={process.env.PUBLIC_URL + "/product/:id"}
              element={<ProductTabLeft />}
            />

            {/* Blog pages */}
            <Route
              path={process.env.PUBLIC_URL + "/blog-standard"}
              element={<BlogStandard />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/blog-no-sidebar"}
              element={<BlogNoSidebar />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/blog-right-sidebar"}
              element={<BlogRightSidebar />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/blog-details-standard"}
              element={<BlogDetailsStandard />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/filtered-products"}
              element={<Filtered />}
            />

            {/* Other pages */}
            <Route
              path={process.env.PUBLIC_URL + "/about"}
              element={<About />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/contact"}
              element={<Contact />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/account"}
              element={<MyAccount />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/login"}
              element={<Login />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/register"}
              element={<Register />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/existing-customer"}
              element={<ExistingCustomer />}
            />

            <Route path={process.env.PUBLIC_URL + "/cart"} element={<Cart />} />
            <Route
              path={process.env.PUBLIC_URL + "/wishlist"}
              element={<Wishlist />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/compare"}
              element={<Compare />}
            />
            <Route
              path={process.env.PUBLIC_URL + "/checkout"}
              element={<Checkout />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/payment"}
              element={<Payment />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/thankyou"}
              element={<ThankYou />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/sizeguide"}
              element={<SizeGuide />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/categories"}
              element={<Categories />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/brands"}
              element={<Brands />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/my-orders"}
              element={<MyOrders />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/return-exchnage-policy"}
              element={<ReturnPolicy />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/shipping-policy"}
              element={<ShippingPolicy />}
            />

            <Route
              path={process.env.PUBLIC_URL + "/privacy-policy"}
              element={<PrivacyPolicy />}
            />

            <Route
              path="/verification/email/:token"
              element={<VerifyEmail />}
            />

            <Route path="*" element={<NotFound />} />
          </Routes>
        </Suspense>
      </ScrollToTop>
      <Toaster position="top-center" />
    </Router>
  );
};

export default App;
