import React, { useState } from "react";
import { Fragment } from "react";
import SEO from "../../components/seo";
import { Link, useLocation, useNavigate } from "react-router-dom";
import LayoutOne from "../../layouts/LayoutOne";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../../wrappers/breadcrumb/Breadcrumb";
import { useEffect } from "react";
import { myOrders as Orders } from "../../store/actions/orderActions";
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import { DateRange } from "react-date-range";
import OrderCard from "../../components/retailer/RetailerOrderCard";
import EmptyOrderCard from "../../components/retailer/EmptyOrderCard";
import { addDays } from 'date-fns';
const MyOrders = () => {
  let { pathname } = useLocation();
  const { myOrders } = useSelector((state) => state.order);
  const { isAuthenticated, user } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [filteredOrders, setFilteredOrders] = useState(myOrders);
  const [state, setState] = useState([
    {
      startDate: new Date(),
      endDate: addDays(new Date(), 7),
      key: 'selection'
    }
  ]);

  const [filterCriteria, setFilterCriteria] = useState({
    startDate: null,
    endDate: null,
    status: null,
    orderID: null,
    userEmailandName: null,
    ProductName: null,
  });

  const renderRetailerOrders = () => {
    if (!isFilterCriteriaSet(filterCriteria)) {
      return myOrders.length > 0 ? <OrderCard orders={myOrders} statusOrder={statusOrder} /> : <EmptyOrderCard />;
    } else {
      return filteredOrders.length > 0 ? <OrderCard orders={filteredOrders} statusOrder={statusOrder} /> : <EmptyOrderCard />;
    }
  };



  const renderDropShiperOrders = () => {
    // Todo : create a component for drop shipers and render it 
    return <div>Drop Shipper Orders</div>
  };

  const renderOrders = () => {
    const renderFunctions = {
      "Retailer": renderRetailerOrders,
      "DropShiper": renderDropShiperOrders,
    }
    const renderFunction = renderFunctions[user.type];
    return renderFunction ? renderFunction() : <EmptyOrderCard />;
  }


  const isFilterCriteriaSet = (criteria) => {
    return Object.values(criteria).some(value => value !== null && value !== '');
  };
  const statusOrder = {
    Processing: "warning",
    Shipped: "info",
    Delivered: "success",
    Cancelled: "danger",
    Received: "info",
    Completed: "success",
    "Awaiting Payment at Store": "warning",
  }
  useEffect(() => {
    if (!isAuthenticated) return navigate("/login");
    dispatch(Orders());
    // eslint-disable-next-line
  }, [isAuthenticated]);

  const handleDateChange = (item) => {
    setState([item.selection]);
    const startDate = new Date(item.selection.startDate);
    const endDate = new Date(item.selection.endDate);
    endDate.setDate(endDate.getDate() + 1);
    setFilterCriteria({ ...filterCriteria, startDate, endDate });

  };
  useEffect(() => {
    applyFilters();
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filterCriteria]);
  const applyFilters = () => {
    const {
      startDate,
      endDate,
      status,
      orderID,
      userEmailandName,
      ProductName,
    } = filterCriteria;
    const filtered = myOrders.filter((order) => {
      const orderDate = new Date(order.createdAt);
      const orderIdMatch = orderID ? order.id.toString().includes(orderID) : true;
      const statusMatch = status ? order.status === status : true;
      const ProductNameMatch = !ProductName || order.orderItems.some((item) => item.name.toLowerCase().includes(ProductName.toLowerCase()));
      const userEmailandNameMatch =
        !userEmailandName ||
        (order.customer &&
          (order.customer.firstName.toLowerCase().includes(userEmailandName.toLowerCase()) ||
            order.customer.email.toLowerCase().includes(userEmailandName.toLowerCase())));
      if (startDate && endDate) {
        return (
          orderDate >= startDate &&
          orderDate <= endDate &&
          orderIdMatch &&
          statusMatch &&
          userEmailandNameMatch &&
          ProductNameMatch
        );
      } else if (startDate) {
        return (
          orderDate >= startDate &&
          orderIdMatch &&
          statusMatch &&
          userEmailandNameMatch &&
          ProductNameMatch
        );
      } else if (endDate) {
        return (
          orderDate <= endDate &&
          orderIdMatch &&
          statusMatch &&
          userEmailandNameMatch &&
          ProductNameMatch
        );
      }
      return (
        orderIdMatch &&
        statusMatch &&
        userEmailandNameMatch &&
        ProductNameMatch
      );
    });

    setFilteredOrders(filtered);
  };
  return (
    <Fragment>
      <SEO
        titleTemplate="My Orders"
        description="My Orders of Remax Wireless."
      />
      <LayoutOne
        headerTop="visible"
        headerContainerClass="container-fluid"
        headerPaddingClass="header-padding-1"
      >
        {/* breadcrumb */}
        <Breadcrumb
          pages={[
            { label: "Home", path: process.env.PUBLIC_URL + "/" },
            {
              label: "My Orders",
              path: process.env.PUBLIC_URL + pathname,
            },
          ]}
        />
        <div className="order-filter-area">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 d-flex justify-content-center">
                <DateRange
                  onChange={handleDateChange}
                  showSelectionPreview={true}
                  moveRangeOnFirstSelection={false}
                  months={2}
                  ranges={state}
                  direction="horizontal"
                  preventSnapRefocus={true}
                  calendarFocus="backwards"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container my-5 py-5">
          <div className="col-lg-12 col-md-12 ml-auto mr-auto my-5">
            {
              renderOrders()
            }
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  );
};

export default MyOrders;