const { createSlice } = require('@reduxjs/toolkit');

const navSlice = createSlice({
    name: "navbar",
    initialState: {
        nav: [],
        loading: false,
    },
    reducers: {
        setNav(state, action) {
            state.nav = action.payload;
            state.loading = false;
        },
        loadingNav(state, action) {
            state.loading = true;
        },
    }
});

export const { setNav, loadingNav } = navSlice.actions;
export default navSlice.reducer;