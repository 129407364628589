import React from 'react';
const RetailerOrderDetails = ({ stores, shipping }) => {
  const calculateOrderTotal = (stores) =>
    stores.reduce((total, store) => total + store.totalItemsPrice, 0);

  return (
    <div className="order-details mt-3">
      {stores.map((store, storeIndex) => (

        <div key={storeIndex}>
          <h3 className='mt-4 text-center pb-3'>Store - {store.storeName}</h3>
          <hr />
          {store.cartItems.map((item, itemIndex) => (
            <>
              <div key={itemIndex} className="d-flex justify-content-between">
                <div>
                  <span>{item.name}</span>
                </div>
                <div>
                  <span>{item.quantity} x ${(item.itemPrice || item.price)} = ${(item.quantity * (item.itemPrice || item.price)).toFixed(2)}</span>
                </div>
              </div>
              <hr />
            </>
          ))}
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="store-total">Sub Total: {store.totalItemsPrice.toFixed(2)}</div>
          </div>
        </div>
      ))}
      <div className="store-total pt-2 total">Shipping Price: {shipping}</div>
      <div className="total">Grand Total: {(parseFloat(calculateOrderTotal(stores)) + shipping).toFixed(2)}</div>
    </div>
  );
};

export default RetailerOrderDetails;
