import { v4 as uuidv4 } from 'uuid';
import cogoToast from 'cogo-toast';
const { createSlice } = require('@reduxjs/toolkit');

const cartSlice = createSlice({
    name: "cart",
    initialState: {
        cartItems: [],
        retailerCart: [],
        selectedStore: null,
        storesToCheckout: [],
    },
    reducers: {
        addToCart(state, action) {
            const product = action.payload;
            if (!product?.variation) {
                const cartItem = state.cartItems.find(item => item.id === product?.id);
                if (!cartItem) {
                    state.cartItems.push({
                        ...product,
                        quantity: product?.quantity ? product?.quantity : 1,
                        cartItemId: uuidv4(),
                        storeId: state?.selectedStore
                    });
                } else {
                    state.cartItems = state.cartItems.map(item => {
                        if (item.cartItemId === cartItem.cartItemId) {
                            return {
                                ...item,
                                quantity: product?.quantity ? item.quantity + product?.quantity : item.quantity + 1
                            }
                        }
                        return item;
                    })
                }

            } else {
                const cartItem = state.cartItems.find(
                    item =>
                        item.id === product?.id &&
                        product?.selectedProductColor &&
                        product?.selectedProductColor === item.selectedProductColor &&
                        product?.selectedProductSize &&
                        product?.selectedProductSize === item.selectedProductSize &&
                        (product?.cartItemId ? product?.cartItemId === item.cartItemId : true)
                );
                if (!cartItem) {
                    state.cartItems.push({
                        ...product,
                        quantity: product?.quantity ? product?.quantity : 1,
                        cartItemId: uuidv4(),
                        storeId: state?.selectedStore
                    });
                } else if (cartItem !== undefined && (cartItem.selectedProductColor !== product?.selectedProductColor || cartItem.selectedProductSize !== product?.selectedProductSize)) {
                    state.cartItems = [
                        ...state.cartItems,
                        {
                            ...product,
                            quantity: product?.quantity ? product?.quantity : 1,
                            cartItemId: uuidv4(),
                            storeId: state?.selectedStore
                        }
                    ]
                } else {
                    state.cartItems = state.cartItems.map(item => {
                        if (item.cartItemId === cartItem.cartItemId) {
                            return {
                                ...item,
                                quantity: product?.quantity ? item.quantity + product?.quantity : item.quantity + 1,
                                selectedProductColor: product?.selectedProductColor,
                                selectedProductSize: product?.selectedProductSize,
                                storeId: state?.selectedStore
                            }
                        }
                        return item;
                    });
                }
            }
            cogoToast.success("Added To Cart", { position: "bottom-left" });
        },
        deleteFromCart(state, action) {
            state.cartItems = state.cartItems.filter(item => item.cartItemId !== action.payload);
            cogoToast.error("Removed From Cart", { position: "bottom-left" });
        },
        decreaseQuantity(state, action) {
            const product = action.payload;
            if (product?.quantity === 1) {
                state.cartItems = state.cartItems.filter(item => item.cartItemId !== product?.cartItemId);
                cogoToast.error("Removed From Cart", { position: "bottom-left" });
            } else {
                state.cartItems = state.cartItems.map(item =>
                    item.cartItemId === product?.cartItemId
                        ? { ...item, quantity: item.quantity - 1 }
                        : item
                );
                cogoToast.warn("Item removed from cart", { position: "bottom-left" });
            }
        },
        increaseQuantity(state, action) {
            const product = action.payload;
            state.cartItems = state.cartItems.map(item => {
                return item.cartItemId === product?.cartItemId
                    ? { ...item, quantity: item.quantity + 1 }
                    : item
            }
            );
            cogoToast.warn("Item1 added in cart", { position: "bottom-left" });
        },
        deleteAllFromCart(state) {
            state.cartItems = []
        },
        setCartItemstoVerified(state, action) {
            state.cartItems = action.payload;
        },
        setSelectedStore(state, action) {
            state.selectedStore = action.payload;
        },
        setStoreCart(state, action) {
            // set seletected stort cart items
            state.cartItems = state?.retailerCart?.find(item => item.storeId === action.payload)?.cartItems || [];
        },
        addToRetailerCart(state, action) {
            const product = action.payload;

            if (!state.retailerCart) {
                state.retailerCart = [];
            }

            const cartItem = state.retailerCart.find(item => item.storeId === state?.selectedStore);

            if (!cartItem) {
                //retailer cart is empty
                state.retailerCart.push({
                    storeId: state?.selectedStore,
                    cartItems: [
                        {
                            ...product,
                            quantity: product?.quantity ? product?.quantity : 1,
                            cartItemId: uuidv4(),
                        }
                    ]
                });
            } else {
                state.retailerCart = state.retailerCart.map(item => {
                    if (item.storeId === state?.selectedStore) {
                        return {
                            ...item,
                            cartItems: [
                                ...item.cartItems,
                                {
                                    ...product,
                                    quantity: product?.quantity ? product?.quantity : 1,
                                    cartItemId: uuidv4(),
                                }
                            ]
                        }
                    }
                    return item;
                })
            }
            state.cartItems = state.retailerCart.find(item => item.storeId === state?.selectedStore)?.cartItems || [];
            cogoToast.success("Added To Cart", { position: "bottom-left" });
        },
        /*******This method remove cartItem from  selected store***********/
        removeFromRetailerCart(state, action) {
            const product = action.payload;
            const selectedStoreCartItem = state.retailerCart.find(item => item.storeId === state?.selectedStore);

            if (selectedStoreCartItem) {
                const updatedCart = state.retailerCart.map(item => {
                    if (item.storeId === state?.selectedStore) {
                        state.cartItems = state.cartItems.filter(item => item.cartItemId !== product);
                        cogoToast.error("Removed From Cart", { position: "bottom-left" });
                        return {
                            ...item,
                            cartItems: item.cartItems.filter(cartItem => cartItem.cartItemId !== product)
                        };
                    }
                    return item;
                }
                );
                state.retailerCart = updatedCart;
            }
        },
        /*******This method remove cartItem from  any store ***********/
        deleteProductFromRetailerCart(state, action) {
            const product = action.payload;

            state.retailerCart = state.retailerCart.map(item => {
                return {
                    ...item,
                    cartItems: item.cartItems.filter(cartItem => cartItem.cartItemId !== product)
                };
            });

            state.cartItems = state.cartItems.filter((item) => item.cartItemId !== product)
            cogoToast.error("Removed From Cart", { position: "bottom-left" });
        },
        increaseRetailerQuantity(state, action) {
            const product = action.payload;
            const cartItem = state.retailerCart.find(item => item.storeId === state?.selectedStore);
            if (cartItem) {
                const updatedCart = state.retailerCart.map(item => {
                    return {
                        ...item,
                        cartItems: item.cartItems.map(cartItem => {

                            if (cartItem.cartItemId === product?.cartItemId) {
                                return {
                                    ...cartItem,
                                    quantity: cartItem.quantity + 1
                                }
                            }
                            return cartItem;
                        })
                    };
                });
                state.retailerCart = updatedCart;
                state.cartItems = state.retailerCart.find(item => item.storeId === state?.selectedStore)?.cartItems || [];
                cogoToast.warn("Item added in cart", { position: "bottom-left" });
            }
        },
        decreaseRetailerQuantity(state, action) {
            const product = action.payload;
            const cartItem = state.retailerCart.find(item => item.storeId === state?.selectedStore);

            if (cartItem) {
                const updatedCart = state.retailerCart.map(item => {
                    if (product?.quantity === 1) {
                        return {
                            ...item,
                            cartItems: item.cartItems.filter(cartItem => cartItem.cartItemId !== product?.cartItemId)
                        };
                    }
                    else {
                        return {
                            ...item,
                            cartItems: item.cartItems.map(cartItem => {
                                if (cartItem.cartItemId === product?.cartItemId) {
                                    return {
                                        ...cartItem,
                                        quantity: cartItem.quantity - 1
                                    }
                                }
                                return cartItem;
                            })
                        };
                    }
                }
                );
                state.retailerCart = updatedCart;
                state.cartItems = state.retailerCart.find(item => item.storeId === state?.selectedStore)?.cartItems || [];
                cogoToast.warn("Item removed from cart", { position: "bottom-left" });
            }
        },

        increaseStockQuantity(state, action) {
            const product = action.payload;
            const cartItem = state.retailerCart.find(item => item.storeId === state?.selectedStore);
            if (cartItem) {
                const updatedCart = state.retailerCart.map(item => {
                    return {
                        ...item,
                        cartItems: item.cartItems.map(cartItem => {

                            if (cartItem.cartItemId === product?.cartItemId) {
                                return {
                                    ...cartItem,
                                    quantity: product?.quantity
                                }
                            }
                            return cartItem;
                        })
                    };
                });
                state.retailerCart = updatedCart;
                state.cartItems = state.retailerCart.find(item => item.storeId === state?.selectedStore)?.cartItems || [];
                cogoToast.warn("Item updated in cart", { position: "bottom-left" });
            }
        },

        checkoutStore(state, action) {
            state.storesToCheckout = action.payload;
        },
        clearStoreCartOnOrderCompletion(state, action) {
            const order = action.payload;
            state.retailerCart = state.retailerCart.map(store => {
                const orderStore = order.stores.find(oStore => oStore.storeId === store.storeId);

                if (orderStore) {
                    // Create a set of productIds that were checked out in this store
                    const checkedOutProductIds = new Set(orderStore.cartItems.map(item => item.productID));

                    // Filter out the cartItems that were checked out
                    const updatedCartItems = store.cartItems.filter(item => !checkedOutProductIds.has(item.productID));

                    // Return the updated store object
                    return {
                        ...store,
                        cartItems: updatedCartItems
                    };
                }

                // If the storeId is not in the order, return the store as it is
                return store;
            });

            const selectedStoreObj = state.retailerCart.find(store => store.storeId === state.selectedStore);
            state.cartItems = selectedStoreObj?.cartItems;


        },

        clearCartOnLogin(state) {
            state.cartItems = [];
            state.retailerCart = [];
        }
    },
});







export const { addToCart, clearStoreCartOnOrderCompletion, deleteFromCart, deleteProductFromRetailerCart, decreaseQuantity, deleteAllFromCart, increaseQuantity, setCartItemstoVerified, setSelectedStore, setStoreCart, addToRetailerCart, removeFromRetailerCart, increaseRetailerQuantity, decreaseRetailerQuantity, checkoutStore, clearCartOnLogin, increaseStockQuantity } = cartSlice.actions;
export default cartSlice.reducer;
