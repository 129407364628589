import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { loadState } from "../helpers/persister";
import productReducer from "./slices/product-slice";
import currencyReducer from "./slices/currency-slice";
import cartReducer from "./slices/cart-slice";
import compareReducer from "./slices/compare-slice";
import wishlistReducer from "./slices/wishlist-slice";
import categorySlice from "./slices/category-slice";
import order from "./slices/orderReducers";
import authSlice from "./slices/auth-slice";
import subCategorySlice from "./slices/subCategory-slice";
import brandSlice from "./slices/brand-slice";
import carrierSlice from "./slices/carrier-slice";
import modelSlice from "./slices/model-slice";
import designSlice from "./slices/desgin-slice";
import bannerSlice from "./slices/banner-slice";
import addressSlice from "./slices/address-slice";
import taxSlice from "./slices/tax-slice";
import adsSlice from "./slices/ads-slice";
import navSlice from "./slices/nav-slice";

const persistedState = loadState();

export const rootReducer = combineReducers({
  navbar: navSlice,
  ad: adsSlice,
  auth: authSlice,
  product: productReducer,
  currency: currencyReducer,
  cart: cartReducer,
  compare: compareReducer,
  wishlist: wishlistReducer,
  category: categorySlice,
  order: order,
  subcategory: subCategorySlice,
  brand: brandSlice,
  carrier: carrierSlice,
  models: modelSlice,
  designs: designSlice,
  banner: bannerSlice,
  store: addressSlice,
  tax: taxSlice,
});


export const store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware(),
});